<script>
import formTermsMixin from '@phoenix/mixins/formTermsMixin';
import modalMixin from '@phoenix/mixins/modalMixin';
import NewsletterForm from '@maison/components/form/NewsletterForm';

export default {
  components: {
    NewsletterForm,
  },

  mixins: [formTermsMixin, modalMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    groups: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      prefillEmailValue: '',
      successfullySubmitted: false,
    };
  },

  computed: {
    modalOptions() {
      return {
        onShow: (event) => {
          const eventProps = this.getModalProps(event);
          this.prefillEmailValue = eventProps.email ?? '';
        },
        onHidden: () => {
          this.successfullySubmitted = false;
        },
      };
    },
  },

  methods: {
    onSuccessCallback() {
      this.successfullySubmitted = true;
    },
  },
};
</script>

<template>
  <modal :id="id" :title="title" :options="modalOptions">
    <template #content>
      <div class="text-block--center">
        <div v-if="subtitle" class="mb-md text-block--center">{{ subtitle }}</div>
        <p v-if="successfullySubmitted">{{ $t('Newsletter.modal.push.success.label') }}</p>
        <newsletter-form
          v-else
          :prefill-email-value="prefillEmailValue"
          :data-cy="id"
          :groups="groups"
          :countries="countries"
          :terms="terms"
          :on-success-callback="onSuccessCallback"
          :show-errors-in-toast="false"
          :show-success-in-toast="false"
          :should-reset-form-on-submit="true"
          single-column
        >
        </newsletter-form>
      </div>
    </template>
  </modal>
</template>
